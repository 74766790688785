<template>
  <form
    role="form"
    class="text-start mt-3"
    @submit.prevent="submit"
  >
    <material-alert
      v-show="error.length"
      color="danger"
    >
      {{error}}
    </material-alert>
    <material-input
      id="username"
      v-model="username"
      type="email"
      :disabled="loading"
      label="E-mailadres"
      name="username"
      is-required
    />
    <material-button
      class="my-4"
      variant="gradient"
      color="primary"
      full-width
    >
      Verder
    </material-button>
  </form>
</template>

<script>
import { requestOTP } from '@/api/providers/account.js';
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: "AccountLogin",

  components: {
    MaterialAlert,
    MaterialButton,
    MaterialInput,
  },

  data: () => ({
    error: '',
    loading: false,
    username: '',
  }),

  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      requestOTP(this.username)
        .then(() => {
          this.$router.push({
            name: 'account-login-password',
            params: {
              username: this.username,
            },
          });
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
};
</script>
